import React from "react";
import SessionList from "./SessionList";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";
import CustomTitle from "../CustomTitle";
import { useSessions } from "../../assets/hooks/customHooks";
import { useEffect } from "react";

export default function Upcoming({ cart }) {
  const navigate = useNavigate();
  const { sessions } = useSessions();
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);

  useEffect(() => {
    setUpcomingSessions(
      sessions.filter(
        (s) =>
          !s?.data?.isSoon &&
          new Date(s?.data?.date).getTime() > new Date().getTime()
      )
    );
  }, []);

  return (
    <>
      {upcomingSessions.length > 0 ? (
        <div
          className={`${!cart && "px-10"} py-10`}
          style={{ backgroundColor: "#DFE3E4" }}
        >
          <CustomTitle
            text={cart ? "Вас может заинтересовать" : "Ближайшие события"}
          />
          <SessionList cart={true} />
          {!cart && (
            <CustomButton
              text="Посмотреть все семинары"
              onClick={() => navigate("/sessions")}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
