import { useEffect, useState } from "react";
import { readFirebase } from "../firebaseFunctions";
import { auth } from "../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { convertCurrency } from "../utils";
import { setEventsRedux } from "../../redux/slices/eventsSlice";
import { setPostsRedux } from "../../redux/slices/postsSlice";
import { setFaqsRedux } from "../../redux/slices/faqsSlice";

export const useSessions = () => {
  const dispatch = useDispatch();
  const sessions = useSelector((state) => state.events.events);

  const getSessionsData = async () => {
    const response = await readFirebase("events");
    if (response) {
      dispatch(setEventsRedux(response));
    }
  };

  useEffect(() => {
    getSessionsData();
  }, []);

  return { sessions };
};

export const useUserEvents = () => {
  const [userEvents, setUserEvents] = useState([]);

  const getEvents = async () => {
    const response = await readFirebase("orders");

    if (response) {
      let filtered = response.filter(
        (x) =>
          x.data?.userEmail &&
          x.data.userEmail === auth?.currentUser?.email &&
          x.data.status === "paid"
      );

      let filterAndFlat = filtered.map((f) => f.data.items).flatMap((x) => x);

      setUserEvents(filterAndFlat);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return { userEvents };
};

export const usePrice = (value) => {
  const [price, setPrice] = useState(`USD ${value}`);
  const currentCurrency = useSelector((state) => state.currency.currency);

  useEffect(() => {
    setPrice(convertCurrency(value, currentCurrency ? currentCurrency : "USD"));
  }, [value, currentCurrency]);

  return { price };
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

export const usePosts = () => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.posts);

  const getPostsData = async () => {
    const response = await readFirebase("posts");
    if (response) {
      dispatch(setPostsRedux(response));
    }
  };

  useEffect(() => {
    getPostsData();
  }, []);

  return { posts };
};

export const useFaqs = () => {
  const dispatch = useDispatch();
  const faqs = useSelector((state) => state.faqs.faqs);

  const getFaqsData = async () => {
    const response = await readFirebase("faqs");
    if (response) {
      dispatch(setFaqsRedux(response));
    }
  };

  useEffect(() => {
    getFaqsData();
  }, []);

  return { faqs };
};
