import React, { useState } from "react";
import { useWindowDimensions } from "../assets/hooks/customHooks";
import { pdfjs, Document, Page } from "react-pdf";
import emotionsPDF from "../assets/data/emotions.pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function EmotionsDocument() {
  const { width } = useWindowDimensions();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <div className="p-10">
        <p>
          Page {pageNumber} of {numPages !== null ? numPages : 0}
        </p>
        <div className="flex flex-row gap-3 mt-3 mb-5">
          <button
            disabled={pageNumber <= 1}
            onClick={() =>
              setPageNumber((prevPageNumber) => prevPageNumber - 1)
            }
            className="bg-slate-300 py-1 px-3 rounded"
          >
            Previous
          </button>
          <button
            disabled={pageNumber >= numPages}
            onClick={() =>
              setPageNumber((prevPageNumber) => prevPageNumber + 1)
            }
            className="bg-slate-300 py-1 px-3 rounded"
          >
            Next
          </button>
        </div>
        <p>
          If you cannot see the document, please use{" "}
          <a
            href="https://firebasestorage.googleapis.com/v0/b/mv-revival.appspot.com/o/documents%2Fdopamine.pdf?alt=media&token=fbf374a6-0cf2-4002-a631-105da017e9de"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            this link.
          </a>
        </p>
      </div>
      <Document
        file={emotionsPDF}
        onLoadSuccess={onDocumentLoadSuccess}
        error={<div>Failed to load PDF file</div>}
      >
        <Page pageNumber={pageNumber} renderTextLayer={false} width={width} />
      </Document>
    </div>
  );
}
