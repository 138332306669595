import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// navigation
import { createRoot } from "react-dom/client";
import { Routes, Route } from "react-router-dom";

// redux
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

// screens
import App from "./App";
import AllSessions from "./containers/AllSessions";
import SessionPage from "./containers/SessionPage";
import Cart from "./containers/Cart";
import Payment from "./containers/Payment/Payment";
import Login from "./containers/Auth/Login";
import Profile from "./containers/Profile/Profile";
import Register from "./containers/Auth/Register";
import ConfirmEmail from "./containers/Auth/ConfirmEmail";
import ResetPassword from "./containers/Auth/ResetPassword";
import OrderConfirmation from "./containers/OrderConfirmation";
import OrdersSection from "./containers/Profile/OrdersSection";
import Admin from "./containers/Admin/Admin";
import AllPosts from "./containers/AllPosts";
import PostPage from "./containers/PostPage";
import PaymentFailure from "./containers/Payment/PaymentFailure";
import ChangeEvent from "./containers/ChangeEvent";
import ChangePost from "./containers/ChangePost";
import ChangeFaq from "./containers/ChangeFaq";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AboutUs from "./containers/AboutUs";
import Contacts from "./containers/Contacts";
import ReviewsPage from "./containers/ReviewsPage";
import LeaveReview from "./containers/LeaveReview";
import BusinessPage from "./containers/BusinessPage";
import { HashRouter } from "react-router-dom";
import TraumaDocument from "./containers/TraumaDocument";
import Terms from "./containers/Terms";
import DopamineDocument from "./containers/DopamineDocument";
import EmotionsDocument from "./containers/EmotionsDocument";
import PrivacyPolicy from "./containers/PrivacyPolicy";

function restructureUrl() {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");

  if (orderId) {
    // Rebuild the hash URL to include order_id in the hash part
    const newHash = `#/order-confirmation?order_id=${orderId}`;
    // Use window.history.replaceState to avoid adding a new entry in the browser's history stack
    window.history.replaceState(
      null,
      "",
      `${window.location.origin}${newHash}`
    );
  }
}

restructureUrl();

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId="80949773835-5eu0j53d3nd2qb0khh123i4l7efua142.apps.googleusercontent.com">
          <HashRouter basename="/">
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/confirm-email" element={<ConfirmEmail />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/profile/order-history"
                element={<OrdersSection />}
              />
              <Route path="/cart" element={<Cart />} />
              <Route path="/payment" element={<Payment />} />
              <Route
                path={`/order-confirmation`}
                element={<OrderConfirmation />}
              />
              <Route path="/payment-failure" element={<PaymentFailure />} />
              <Route path="/sessions" element={<AllSessions />} />
              <Route path="/sessions/:sessionId" element={<SessionPage />} />
              <Route path="/change-event" element={<ChangeEvent />} />
              <Route path="/change-post" element={<ChangePost />} />
              <Route path="/change-faq" element={<ChangeFaq />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/posts" element={<AllPosts />} />
              <Route path="/posts/:postId" element={<PostPage />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/business" element={<BusinessPage />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/reviews" element={<ReviewsPage />} />
              <Route path="/leave-review" element={<LeaveReview />} />
              <Route
                path="/documents/present/trauma/09852686546564098"
                element={<TraumaDocument />}
              />
              <Route
                path="/documents/present/dopamine/09852686546564098"
                element={<DopamineDocument />}
              />
              <Route
                path="/documents/present/emotions/09852686546564099"
                element={<EmotionsDocument />}
              />
              <Route path="/payment-terms-and-conditions" element={<Terms />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </HashRouter>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
