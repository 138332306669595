import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Bread from "../../components/Bread";
import { addFirebase, readFirebase } from "../../assets/firebaseFunctions";
import { auth } from "../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import getStripe from "../../getStripe";
import axios from "axios";

export default function Payment() {
  const cart = useSelector((state) => state.cart.products);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (cart.length === 0) {
      navigate("/");
    }
  }, []);

  const createOrder = async (orderNumber) => {
    const events = await cart.map((c) => {
      return {
        eventId: c.id,
        eventName: c.data.title,
        eventPrice: c.data.price,
      };
    });
    const newOrder = {
      userEmail: auth?.currentUser?.email || email,
      total: cart.reduce((acc, item) => acc + parseInt(item.data.price), 0),
      items: events,
      status: "created",
      createdAt: new Date().toDateString(),
      orderNumber: orderNumber,
    };
    const newOrderId = await addFirebase("orders", newOrder);

    return newOrderId;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
  };

  const pay = async () => {
    console.log("Payment initiated...");

    if (state?.guest) {
      if (email === "" || !validateEmail(email)) {
        alert("Please enter a valid email address");
        return;
      }
    }

    try {
      const response = await axios.post(
        "https://localhost:4000/create-order",
        {
          // for test payment use hard-coded 100, then uncomment below for prod
          // amount: cart.reduce(
          //   (acc, item) => acc + parseInt(item.data.price),
          //   0
          // ),
          amount: 100,
          email: email,
        },
        {
          auth: {
            username: process.env.REACT_APP_BCC_USERNAME,
            password: process.env.REACT_APP_BCC_PASSWORD,
          },
        }
      );
      console.log("API response > create order:", response);
      if (response.status === 200) {
        console.log(
          "Order successfully created. Order number: ",
          response.data.orders[0].id
        );
        await createOrder(response.data.orders[0].id);
        window.location.replace(response.data.location);
      } else {
        alert("Error while creating order");
      }
    } catch (error) {
      console.error("API error > create order:", error.message);
    }
  };

  // const pay = async () => {
  //   console.log("Ping initiated...");

  //   try {
  //     const response = await axios.get(
  //       "https://localhost:4000/ping",
  //       {},
  //       {
  //         auth: {
  //           username: process.env.REACT_APP_BCC_USERNAME,
  //           password: process.env.REACT_APP_BCC_PASSWORD,
  //         },
  //       }
  //     );
  //     console.log("API response > ping:", response);
  //   } catch (error) {
  //     console.error("API error > ping:", error);
  //   }
  // };

  return (
    <div>
      <Header />
      <Bread />
      <div className="p-10 text-center">
        <h2 className=" text-2xl font-bold my-10">
          You are about to pay
          <br />
          {/* {price} */}
          KZT {cart.reduce((acc, item) => acc + parseInt(item.data.price), 0)}
        </h2>
        <h2 className=" text-xl my-10">
          When you click "Pay", you will be redirected to bank payment page
        </h2>
        <div className="flex flex-col justify-between w-full m-auto items-center gap-3">
          {state?.guest && (
            <div>
              <p className="italic text-slate-500 text-xs mb-1 w-[70%] text-left">
                We do not store your email, just need it to send you the receipt
              </p>
              <input
                placeholder="Specify your email"
                className="border-2 border-gray-300 p-3 rounded w-full"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          )}

          <button
            className="bg-blue-500 text-white py-3 rounded w-1/3"
            onClick={pay}
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  );
}
