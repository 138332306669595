import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from "../../redux/slices/cartSlice";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { usePrice, useUserEvents } from "../../assets/hooks/customHooks";
import {
  addToWishList,
  removeFromWishList,
} from "../../assets/sessionFunctions";
import {
  addToWishListRedux,
  removeFromWishListRedux,
} from "../../redux/slices/eventsSlice";

export default function SessionCardCarousel({ sessionData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.products);
  const { usersEvents } = useUserEvents();
  const { price } = usePrice(sessionData.data.price);

  const wishlist = (session) => {
    if (auth.currentUser) {
      addToWishList(session);
      dispatch(
        addToWishListRedux({ id: session.id, user: auth.currentUser.email })
      );
    } else {
      alert("Please login first");
    }
  };

  const unwishlist = (session) => {
    removeFromWishList(session);
    dispatch(
      removeFromWishListRedux({ id: session.id, user: auth.currentUser.email })
    );
  };

  return (
    <div className="bg-slate-100 rounded flex flex-col justify-between cursor-pointer p-3">
      {[
        "marina.kim.mk90@gmail.com",
        "vik.gorlanova@gmail.com",
        "maxim.lazyrin@gmail.com",
      ].includes(auth.currentUser?.email) && (
        <SettingsIcon
          onClick={() =>
            navigate("/change-event", { state: { event: sessionData } })
          }
          className="text-slate-300"
        />
      )}
      <div
        onClick={() => navigate(`/sessions/${sessionData.id}`)}
        className="p-3 max-w-72 min-w-72 sm:max-w-96 sm:min-w-96"
      >
        <div className="flex flex-row items-center justify-center">
          <img
            src={sessionData.data.image}
            alt={sessionData.data.title}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div className="text-left mt-3">
          <div className="flex">
            <h3 className="font-bold mb-3">{sessionData.data.title}</h3>
          </div>
          <div>
            <p className="text-sm">{sessionData.data.description}</p>
          </div>
          <div>
            <p className="text-sm my-3">
              Когда:{" "}
              {sessionData.data.isSoon
                ? "Скоро"
                : new Date(sessionData.data.date).toLocaleDateString("ru-RU", {
                    day: "2-digit",
                    month: "long",
                  })}
            </p>
            {!sessionData.data.isSoon && (
              <p className="text-sm my-3">
                Время: {sessionData.data.time} (время Алматы, GMT +5)
              </p>
            )}
          </div>
        </div>
      </div>

      {cart?.length > 0 && cart.map((c) => c.id).includes(sessionData.id) ? (
        <div className="flex justify-between gap-1">
          <Link
            to="/cart"
            className="bg-blue-500 text-white p-1 rounded my-3 text-center p-2 w-3/4"
          >
            Перейти в корзину
          </Link>
          <button
            onClick={() => dispatch(removeFromCart(sessionData.id))}
            className="bg-slate-500 text-white p-1 rounded my-3 text-center p-2"
          >
            Удалить
          </button>
        </div>
      ) : usersEvents?.filter((e) => e.eventId === sessionData.id).length >
        0 ? (
        <div
          className=" text-white p-2 rounded my-3 text-center"
          style={{ backgroundColor: "#DFE3E4" }}
        >
          <p style={{ color: "#808080" }}>Вы уже купили</p>
        </div>
      ) : parseInt(sessionData.data.slots) === 0 ? (
        <div className="flex gap-3">
          <div className="bg-slate-200 w-1/2 text-slate-500 p-2 rounded my-3 text-center">
            Все раскупили 🥲
          </div>
          <div
            onClick={
              sessionData.data.wishlisted?.filter(
                (x) => x === auth?.currentUser?.email
              ).length > 0
                ? () => unwishlist(sessionData)
                : () => wishlist(sessionData)
            }
            className="cursor-pointer bg-indigo-500 w-1/2 text-white  p-2 rounded my-3 text-center"
          >
            {sessionData.data.wishlisted?.filter(
              (x) => x === auth?.currentUser?.email
            ).length > 0
              ? "Remove from wish list"
              : "Add to wish list"}
          </div>
        </div>
      ) : (
        <Link
          to={`/sessions/${sessionData.id}`}
          className=" text-white p-2 rounded my-3 text-center m-auto w-full"
          style={{ backgroundColor: "#CF9F4B" }}
        >
          {/* Занять место */}
          Посмотреть детали
        </Link>
      )}
    </div>
  );
}
